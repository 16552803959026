import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container, Divider, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import logo from '../assets/logo.jpeg';
import PlaceIcon from '@mui/icons-material/Place';
import styled from '@emotion/styled';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: 'black'
        }
    },
});

const sections = [
    { title: 'Cartelera', url: '/cartelera' },
    { title: 'Clases', url: '/clases' },
    { title: 'Cenital', url: '/cenital' },
    { title: '¿Dónde estamos?', url: '/direccion' },
    { title: 'Sobre nosotros', url: '/about' },
];

const Image = styled.img({
    padding: '0',
    margin: '0',
    maxWidth: '100%',
    alignSelf: 'center'
});

export default function Layout({ children, title }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Toolbar
                    component="nav"
                    variant="dense"
                    sx={{ justifyContent: 'space-between', overflowX: 'auto' }}
                >
                    {sections.map((section) => (
                        <Link
                            color="inherit"
                            noWrap
                            key={section.title}
                            variant="body2"
                            href={section.url}
                            sx={{ p: 1, flexShrink: 0, display: { xs: 'none', sm: 'block' } }}
                            underline="none"
                        >
                            {section.title}
                        </Link>
                    ))}

                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {sections.map((section) => (
                            <MenuItem key={section.title} component="a" href={section.url} onClick={handleClose}>{section.title}</MenuItem>
                        ))}
                    </Menu>

                </Toolbar>

                <Divider />

                <main>
                    <Image src={logo} alt="Par1000 logo" />

                    <Divider />

                    <Typography sx={{ my: 4 }}
                        component="h1"
                        variant="h4"
                        align="center"
                        gutterBottom
                    >
                        {title}
                    </Typography>

                    {children}

                </main>
            </Container>
            <Box component="footer" sx={{ py: 6 }}>
                <Container maxWidth="lg">
                    <Typography variant="h6" align="center" gutterBottom>
                        PAR1000 Aula Teatro
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="p"
                        display='flex'
                        alignItems='center'
                        flexWrap='wrap'
                        justifyContent='center'
                    >
                        <PlaceIcon></PlaceIcon>
                        Primera Junta 791, Junín, Buenos Aires, Argentina
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="https://ebt.ar/">
                            ebt.ar
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Container>
            </Box>
        </ThemeProvider>
    );
}