import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@mui/material"
import Layout from "../components/Layout"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import EastIcon from '@mui/icons-material/East';

const shorcuts = [
    { title: 'Cartelera', url: '/cartelera', description: ' Todas las opciones para venir a disfrutar!', icon: CalendarMonthIcon, img: 'https://drive.google.com/uc?export=view&id=1prYckm6O5rdZ3sNo6SUzn8fsjzYbBdjZ' },
    { title: 'Clases', url: '/clases', description: ' Todavía no te animaste? Mirá todo lo que podés hacer!', icon: SchoolIcon, img: 'https://drive.google.com/uc?export=view&id=1r8lx40Cxs6tCs1YEUZrCDFQPFzZKGqzb' },
];

export default function Home() {
    return (
        <Layout title="El espacio ideal para vos y tu espectáculo">
            <Grid container spacing={4}>
                {shorcuts.map((shortcut) => (
                    <Grid item xs={12} md={6}>
                        <CardActionArea component="a" href={shortcut.url}>
                            <Card sx={{ display: 'flex', height: '200px' }}>
                                <CardContent sx={{ flex: 1 }}>
                                    <Typography
                                        component="h2" variant="h5"
                                        display='flex'
                                        alignItems='center'
                                        flexWrap='wrap'
                                        justifyContent='left'>
                                        <shortcut.icon sx={{ mr: 1, display: { xs: 'none', sm: 'block' } }}></shortcut.icon>
                                        {shortcut.title}
                                    </Typography>
                                    <Typography variant="subtitle1" paragraph>
                                        {shortcut.description}
                                    </Typography>
                                    <EastIcon></EastIcon>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 200 }}
                                    image={shortcut.img}
                                    alt={shortcut.title}
                                />
                            </Card>
                        </CardActionArea>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}