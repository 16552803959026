import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MenuItem, Paper, TextField } from '@mui/material';

export default function Compra({ obra }) {
  const [horario, setHorario] = React.useState('');
  const [cantidad, setCantidad] = React.useState(1);
  const [disponibles, setDisponibles] = React.useState(0);
  const [nombre, setNombre] = React.useState('');

  const cancelar = () => {
    setHorario('');
    setCantidad(1);
    setDisponibles(0);
    setNombre('');
  };

  return (
    <Box>
      <Paper sx={{ p: 2, my: 4 }}>
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          Comprar entradas
        </Typography>

        <TextField select label='Seleccione una función' fullWidth sx={{ maxWidth: '250px', mr: 2, mb: 2 }} 
          onChange={(e) => setHorario(e.target.value)} 
          value={horario ?? ''}>
          {obra.funciones.map((funcion) => (
            funcion.disponibles > 0 &&
              <MenuItem key={`${funcion.fecha}  ${funcion.hora}hs`} value={`${funcion.fecha}  ${funcion.hora}hs`}
                onClick={() => setDisponibles(funcion.disponibles)}>
                {`${funcion.fecha}  ${funcion.hora}hs`}
              </MenuItem>
          ))}
        </TextField>

        <TextField label="Cantidad"
          value={cantidad} error={isNaN(parseInt(cantidad)) || parseInt(cantidad) <= 0 || parseInt(cantidad) > disponibles}
          onChange={(e) => setCantidad(e.target.value)}
          helperText={`${disponibles} entradas disponibles`} 
          sx={{ mr: 2, mb: 2 }}/>

        <TextField label="Nombre" placeholder="Ingrese su nombre" onChange={(e) => setNombre(e.target.value)} value={nombre} />
      </Paper>

      {horario !== '' && parseInt(cantidad) > 0 && parseInt(cantidad) <= disponibles && nombre !== '' && (
        <Paper sx={{ p: 2 }}>
          <Typography>{`${cantidad} entradas para la funcion ${horario} a nombre de ${nombre}`}</Typography>
          <Button onClick={cancelar} sx={{ mt: 1, mr: 1 }}>
            Cancelar
          </Button>
          <Button sx={{ mt: 1, mr: 1 }} variant="contained" component='a'
            href={`https://api.whatsapp.com/send?phone=${obra.contacto}&text=Me gustaría reservar 4 entradas para ver ${obra.nombre} en la función ${horario} a nombre de ${nombre}`}
            target='_blank'>
            Reservar
          </Button>
        </Paper>
      )}
    </Box>
  );
}