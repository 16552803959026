import { Funcion } from "../model/Funcion.class";
import { Obra } from "../model/Obra.class";
import { readRemoteFile } from "react-papaparse";

const obrasURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQwFFpNm9riFsuvhFSK6ItpPLn1YLTn8iyebUfeDYc4TaYGQL0al3Ma6D4ytez8RFbt2MUyYs88Dn5U/pub?output=csv';
const fechasURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQwFFpNm9riFsuvhFSK6ItpPLn1YLTn8iyebUfeDYc4TaYGQL0al3Ma6D4ytez8RFbt2MUyYs88Dn5U/pub?gid=372736787&output=csv';

export const getObras = () => {

    return new Promise((resolve, reject) => {
        readRemoteFile(
            obrasURL,
            {
                header: true,
                download: true,
                complete: (resultsObras) => {

                    const obras = new Map(
                        resultsObras.data.map(obra => {
                            return [obra.identificador, new Obra(obra.nombre, obra.descripcion, obra.flyer, obra.contacto)];
                        }),
                    );

                    readRemoteFile(
                        fechasURL,
                        {
                            header: true,
                            download: true,
                            complete: (resultsFunciones) => {
                                resultsFunciones.data.forEach((funcion) => {
                                    obras.get(funcion.obra).agregarFuncion(new Funcion(funcion.fecha, funcion.hora, funcion.disponibles))
                                });

                                console.log(Array.from(obras.values()));

                                resolve(Array.from(obras.values()));
                            },
                            error: (error) => {
                                reject(error);
                            },
                        }
                    );

                },
                error: (error) => {
                    reject(error);
                },
            }
        );
    });
}