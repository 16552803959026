export class Obra {
    constructor(nombre, descripcion, flyer, contacto) {
      this.nombre = nombre;
      this.descripcion = descripcion;
      this.flyer = flyer;
      this.contacto = contacto;
      this.funciones = [];
    }

    // Método
    agregarFuncion(funcion) {
      this.funciones.push(funcion);
    }
  }
  