import Layout from "../components/Layout";
import { APIProvider, InfoWindow, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';
import { Paper, Typography } from "@mui/material";

export default function Direccion() {
    const [markerRef, marker] = useMarkerRef();
    return (
        <Layout title="¿Dónde estamos?">
            <Paper sx={{ height: '500px' }}>
                <APIProvider apiKey={'AIzaSyDrQR41DqEmEF7GZt8AN-0Rzdrph1aheXY'}>
                    <Map
                        zoom={18}
                        center={{ lat: -34.576171875, lng: -60.943572998046875 }}
                        disableDefaultUI={true}
                    >
                        <Marker ref={markerRef} position={{ lat: -34.576171875, lng: -60.943572998046875 }} />

                        <InfoWindow anchor={marker} position={{ lat: -34.576171875, lng: -60.943572998046875 }}>
                            <Typography color='black'>
                                Primera Junta 791, Junín, Buenos Aires, Argentina
                            </Typography>
                        </InfoWindow>
                    </Map>
                </APIProvider>
            </Paper>
        </Layout>
    )
}