import Layout from "../components/Layout";
import avatar_ariel from '../assets/avatar_ariel.jpg';
import avatar_horacio from '../assets/avatar_horacio.jpeg';
import { Card, CardActions, CardContent, Grid, Typography, Button, Avatar } from "@mui/material";

const integrantes = [
    { nombre: 'Ariel Casas', ig: 'https://www.instagram.com/arielcasaselprimo/', description: 'A lo largo de su trayectoria trabajo en Cine, Teatro, TV, Radio y durante más de cinco años estuvo de gira haciendo diferentes shows de Stand – up. En cine cuenta con trabajos en “La liebre ciega” (en post producción) de Salvador Roselli, “Madraza” de Hernán Aguilar, “Permitidos” de Ariel Winograd y “Betibu” de Miguel Cohan, entre otras… En España protagonizo la película “My Way” de Toni Salgot, actuó en “REC 2” de Jaume Balagueró y Paco Plaza encarnando el querido personaje de “Larra” y participo en “25 kilates” de Patxi Amezcua, “Yo soy la Juani” de Bigas Luna y protagonizo el corto “Lazarus Taxon” de Denis Rovira. En TV participo en numerosas series entre las que podemos citar “Las Estrellas”, “Cartoneros”, “Divina”, “Aliados”… En España participo en “Los Hombres de Paco”, “La que se avecina”, “Cuerpo a la carta”… En teatro trabajo tanto en el underground, como en el circuito comercial. Algunos títulos que podemos citar “Blá!Blá!Blá!” (Stand – Down) de Ariel Casas, “El Baño” de Alejandro Miguel, “Camara Lenta” de Eduardo Pavlosvsky, “Romeo y Julieta” de William Shakespeare, “La Pecadora” y “Así es la vida” En España coprotagonizó “Las Listas” de Julio Wallovits', img: avatar_ariel },
    { nombre: 'Horacio Jáuregui', ig: 'https://www.instagram.com/horaciosilveriojauregui/', description: 'La lechera (Actor) Todo lo sólido se desvanece en el aire (Actor) La noche más larga (Autor) Bestias (Asistente de sonido) La noche más larga (Actor, Director)', img: avatar_horacio },
];

export default function About() {
    return (
        <Layout title="Sobre nosotros">
            <Grid container spacing={4}>
                {integrantes.map((integrante) => (
                    <Grid item xs={12} md={6}>
                        <Card sx={{display: 'flex', flexDirection: 'column'}}>
                            {/*<CardMedia
                                component="img"
                                alt="green iguana"
                                image={integrante.img}
                            />*/}
                            <Avatar
                                alt={integrante.nombre}
                                src={integrante.img}
                                sx={{ width: 200, height: 200, alignSelf: 'center', mt: 4 }}
                            />
                                <Typography gutterBottom variant="h5" component="div" sx={{ alignSelf: 'center'}}>
                                    {integrante.nombre}
                                </Typography>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {integrante.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button component="a" href={integrante.ig} target="_blank" size="small">Instagram</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    )
}