import { Box, Button, Card, CardContent, CardMedia, Chip, Grid, ImageList, ImageListItem, LinearProgress, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { getObras } from "../services/obras.service";
import Compra from "../components/Compra";

const Cartelera = () => {
  const [cartelera, setCartelera] = useState(null);
  const [detail, setDetail] = useState({ opened: false, obra: null });

  useEffect(() => {
    getObras()
      .then((obras) => {
        setCartelera(obras);
      })
      .catch((error) => {
        console.error(error)
      })
  }, []);

  return (
    <Layout title="Cartelera">

      {cartelera ? (

        detail.opened ? (
          <>

            <Button variant="outlined"
              sx={{ mb: 4 }}
              startIcon={<ArrowBackIcon />}
              onClick={() => setDetail({ opened: false, obra: null })}>
              Volver a cartelera
            </Button>

            <Card sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardMedia
                  component="img"
                  sx={{ display: { xs: 'block', sm: 'none' } }}
                  image={detail.obra.flyer}
                />
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    {detail.obra.nombre}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    {detail.obra.descripcion}
                  </Typography>
                </CardContent>
                <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ pl: 1, pb: 1, gap: 1 }}>

                  {detail.obra.funciones.length === 0 && 'No hay próximas funciones'}

                  {detail.obra.funciones.map((funcion) => (
                    <Grid key={funcion.fecha + funcion.hora} item xs="auto">
                      {funcion.disponibles <= 0 ?
                        (<Chip label={`${funcion.fecha}  ${funcion.hora}hs`} 
                          title="No hay entradas disponibles"/>)
                        :
                        (<Chip variant="outlined"
                          label={`${funcion.fecha}  ${funcion.hora}hs`}
                          color={(funcion.disponibles <= 10 ? 'warning' : 'success')} />)
                      }
                    </Grid>
                  ))}

                </Grid>
              </Box>
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, display: { xs: 'none', sm: 'block' } }}
                image={detail.obra.flyer}
              />
            </Card>

            {detail.obra.funciones.length > 0 && detail.obra.funciones.some((funcion) => funcion.disponibles > 0) && <Compra obra={detail.obra} />}

          </>
        ) : (


          <ImageList variant="masonry" gap={20}
            sx={{
              columnCount: {
                xs: '1 !important',
                sm: '2 !important',
                md: '3 !important',
              },
            }}
          >
            {cartelera.map(obra => (
              <ImageListItem key={obra.nombre}>
                <img
                  src={obra.flyer}
                  alt={obra.nombre}
                  loading="lazy"
                  style={{cursor: 'pointer'}}
                  onClick={() => setDetail({ opened: true, obra: obra })}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )
      ) : (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="inherit" />
        </Box>
      )}

    </Layout>
  );
};

export default Cartelera;
