import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import About from './pages/About';
import Cartelera from './pages/Cartelera';
import Cenital from './pages/Cenital';
import Clases from './pages/Clases';
import Direccion from './pages/Direccion';
import Home from './pages/Home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/cartelera",
    element: <Cartelera/>,
  },
  {
    path: "/clases",
    element: <Clases/>,
  },
  {
    path: "/cenital",
    element: <Cenital/>,
  },
  {
    path: "/direccion",
    element: <Direccion/>,
  },
  {
    path: "/about",
    element: <About/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
